
.add-faqheader  {
    margin: 30px 0;
    h3 {
        font-weight: 700;
        color: #242526;
        font-size: 32px;
        text-transform: uppercase;
        text-shadow: 2px 2px 2px #CE5937;
        position: relative;
        display: inline-block;
        &::after {
            position: absolute;
            content: "";
            left: 50%;
            bottom:-15px;
            transform: translate(-50%, -50%);
            background: #CE5937;;
            height: 3px;
            width: 30%;
            
        }
    }
}

.add-faq-form {
    max-width: 700px;
    margin: auto;
}