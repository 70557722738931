.single-course-hero {
  background: #001722;
  padding: 50px 0;
  margin-top: 100px;
  .course-hero-left {
    h2 {
      font-weight: 700;
      color: #ffcb00;
      font-size: 32px;
      margin-bottom: 25px;
    }
    .list {
      padding: 0;
      li {
        display: block;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        line-height: 32px;
        span {
          color: #3ec336;
          display: inline-block;
        }
      }
    }
    p {
      color: #fff;
    }

    .hero-btns {
      margin-top: 30px;
      a {
        text-decoration: none;
        display: inline-block;
        color: #fff;
        border-radius: 5px;
        padding: 8px 20px;
        outline: none;
        background: #01aeef;
        font-weight: 600;
        margin: 0 5px;
        border: none;
        transition: all 0.5s ease;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .course-hero-right {
    img {
      display: inline-block;
      width: 100%;
    }
  }
}

.course-details {
  padding: 50px 0;
  .course-details-left {
    .course-img {
      img {
        display: inline-block;
        width: 100%;
      }
    }
    .courses-details-desc-style-two {
      margin-top: 35px;
      h3 {
        margin-bottom: 15px;
        font-size: 24px;
        font-weight: 800;
      }
      p {
        color: #606060;
        margin-bottom: 15px;
        line-height: 1.6;
        font-size: 16px;
      }
      .description-features-list {
        padding-left: 15px;
        margin-bottom: 15px;
        list-style-type: none;
        li {
          color: #606060;
          margin-bottom: 10px;
          position: relative;
          padding-left: 17px;
          line-height: 1.8;
          &:before {
            position: absolute;
            left: 0;
            content: "";
            top: 10px;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #221638;
          }
        }
      }
      .courses-curriculum {
        border: 1px solid #eee;
        border-radius: 5px;
        padding: 30px;
        margin-top: 20px;
        margin-bottom: 30px;
        ul {
          width: 100%;
          height: 300px;
          overflow-x: hidden;
          position: sticky;
          li {
            background-color: #f8f9f8;
            color: #221638;
            position: relative;
            padding: 14.5px 30px 14.5px 75px;
            margin-left: -30px;
            margin-right: -30px;
            font-size: 14.5px;
            font-weight: 700;
            transition: all 0.5s ease;
            &:nth-child(odd) {
              background: rgb(235, 235, 235);
            }

            &:hover {
              cursor: pointer;
              background: #ddd;
            }

            svg {
              position: absolute;
              left: 35px;
              color: #fe4a55;
              top: 15px;
              font-size: 24px;
            }

            span {
              display: inline-block;
              color: #fe4a55;
              margin-right: 15px;
            }
          }
        }
      }
      .why-you-learn {
        margin: 20px 0;
        ul {
          display: -webkit-flex;
          display: -moz-box;
          display: flex;
          -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
          list-style-type: none;
          padding-left: 15px;
          margin-left: -10px;
          margin-right: -10px;
          margin-bottom: -12px;
          li {
            padding-right: 10px;
            padding-bottom: 12px;
            position: relative;
            display: block;
            color: #221638;
            padding-left: 28px;
            line-height: 1.7;
            font-weight: 500;
            width: 50%;

            span {
              position: absolute;
              left: 0;
              top: 3px;
              color: #fe4a55;
              font-size: 13px;
            }
          }
        }
      }
      .requirements-list {
        padding-left: 15px;
        margin-bottom: 0;
        list-style-type: none;

        li {
          color: #606060;
          margin-bottom: 10px;
          position: relative;
          padding-left: 17px;
          &::before {
            position: absolute;
            left: 0;
            content: "";
            top: 10px;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #221638;
          }
        }
      }
      .audience-list {
        padding-left: 15px;
        margin-bottom: 0;
        list-style-type: none;

        li {
          color: #606060;
          margin-bottom: 10px;
          position: relative;
          padding-left: 17px;
          line-height: 1.8;
          &:before {
            position: absolute;
            left: 0;
            content: "";
            top: 10px;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            background-color: #221638;
          }
        }
      }
    }
  }
  .courses-sidebar-information {
    background-color: #fff;
    box-shadow: 0 8px 16px 0 rgb(146 184 255 / 20%);
    padding: 30px;
    .info {
      margin-bottom: 0;
      list-style-type: none;
      padding-left: 0;
      li {
        border-bottom: 1px dashed #eee;
        color: #606060;
        font-size: 17px;
        font-weight: 600;
        padding-top: 15px;
        padding-bottom: 13px;
        span {
          font-weight: 800;
          color: #221638;
          position: relative;
          padding-left: 28px;
        }

        svg {
          color: #fe4a55;
          position: absolute;
          left: 0;
          font-weight: 400;
          top: 2px;
          font-size: 25px;
        }
      }
    }
    .btn-box {
      .default-btn {
        border: none;
        position: relative;
        display: inline-block;
        text-align: center;
        overflow: hidden;
        z-index: 1;
        color: #fff;
        background-color: #fe4a55;
        -moz-transition: 0.5s;
        transition: 0.5s;
        border-radius: 5px;
        font-weight: 700;
        font-size: 16px;
        padding: 11px 30px 11px 55px;
        display: block;
        padding: 12px 30px;
        text-decoration: none;
      }
    }
  }
}
