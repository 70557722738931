/*===================
course-area start
=====================*/
.course-area,
.about-area,
.gallery-area,
.contact-area {
  padding-top: 170px;
  margin-top: -80px;
}

.upper-content {
  max-width: 700px;
  margin: 0 auto;
}

.upper-content h2 {
  font-size: 36px;
  font-weight: 700;
  color: #191919;
  text-transform: uppercase;
}

.upper-content h2::after {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background: #218a42;
  margin: 10px auto;
}

.upper-content p {
  font-size: 15px;
  font-weight: 400;
  color: #191919;
  overflow: hidden;
}

.course-item {
  margin: 16px 0;
}

.course-item img {
  width: 100%;
}

.course-cnt h4 {
  font-size: 22px;
  font-weight: 700;
  color: #191919;
  margin: 16px 0 10px;
}

.course-cnt a {
  text-decoration: none !important;
}

.course-cnt p {
  font-size: 16px;
  font-weight: 400;
  color: #191919;
}

.course-cnt .link {
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
  color: #218a42;
  background: transparent;
  border: 1px solid #218a42;
  display: inline-block;
  padding: 10px 30px;
  border-radius: 5px;
  margin-top: 8px;
  transition: 0.2s all ease;
  text-decoration: none;
}

.course-cnt a i {
  font-size: 15px;
  display: inline-block;
  margin-left: 5px;
}

.course-cnt .link:hover {
  background: #218a42;
  color: #fff;
}

/* accordian */
.faq-box {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.faq-box h3 {
  font-size: 20px;
  font-weight: 700;
  color: #191919;
  text-align: center;
  margin-bottom: 12px;
}

.faq-box button {
  font-size: 18px;
  font-weight: 500;
  color: #fff !important;
  background: #001722 !important;
  border: none;
  outline: none;
  box-shadow: none !important;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
}

.accordion-item {
  border: 2px solid #ddd;
}

.accordion-body {
  border: 1px solid #777;
}

.accordion-body p {
  font-size: 16px;
  font-weight: 400;
  color: #191919;
  margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
  .course-area,
  .about-area,
  .gallery-area,
  .contact-area {
    padding-top: 100px;
    margin-top: -32px;
  }
}
