#sidebar {
  background: #020509;
  grid-area: sidebar;
  overflow-y: auto;
  padding: 20px;
  transition: all 05s;
  height: 100%;
  width: 220px;
  padding-right: 0;
}

.sidebarWrapper {
  height: 100vh;
  overflow: auto;
}

.closeNav {
  position: absolute;
  right: 15px;
  top: 15px;
}

.sidebar__title {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f3f4f6;
  margin-bottom: 30px;
  /* color: #E85B6B; */
}

.sidebar__img {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
}

.sidebar__title > div > img {
  width: 75px;
  object-fit: contain;
  display: block;
}

.sidebar__title > div > h1 {
  font-size: 18px;
  display: inline-block;
  margin-top: 20px;
}

.sidebar__title > i {
  font-size: 18px;
  display: none;
}

.sidebar__menu > h2 {
  color: #3ea175;
  font-size: 16px;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 10px;
  font-weight: 700;
}

.sidebar__link {
  color: #f3f4f6;
  padding: 7px 10px;
  border-radius: 3px;
  align-items: center;
  display: flex;
}

.active_menu_link {
  background: rgba(62, 161, 117, 0.3);
  color: #3ea175;
}

.active_menu_link a {
  color: #3ea175 !important;
}

.sidebar__link > a {
  text-decoration: none;
  color: #a5aaad;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.sidebar__link > a > span {
  display: inline-block;
  margin-left: 10px;
}

.sidebar__link > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar__logout {
  margin-top: 20px;
  padding: 10px;
  color: #e65061;
}

.sidebar__logout > a {
  text-decoration: none;
  color: #e65061;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar__logout > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar_responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
  transition: all 5s ease;
}

@media only screen and (max-width: 978px) {
  #sidebar {
    display: none;
  }

  .sidebar__title > i {
    display: inline;
  }
}
