/*===================
about-area start
=====================*/
.about-area {
  margin-top: -50px;
  overflow: hidden;
}

.about-main {
  padding: 40px 0 20px;
}
.about-right img {
  width: 100%;
}

.about-item img {
  max-width: 50px;
}

.about-item h4 {
  font-size: 24px;
  font-weight: 600;
  color: #191919;
  margin: 8px 0;
}

.about-item p {
  font-size: 16px;
  font-weight: 400;
  color: #191919;
  margin: 0;
}

.about-item {
  margin: 15px 0;
}
