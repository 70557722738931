.carousel-control-next, .carousel-control-prev {
    top: 50%;
    display: inline-block;
    width: 40px;
    height: 40px;
}



.carousel-control-prev {
    left: 35px;
}

.carousel-control-next {
    right:35px;
}


@media screen and (max-width: 767px) {
    .carousel-control-next, .carousel-control-prev {
display: none;
    }
    
}