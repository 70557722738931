/*===================
footer-area start
=====================*/
.footer-area {
  background: #001722;
  overflow: hidden;
  padding: 75px 0 25px;
}

.footer-item .footer-logo {
  max-width: 160px;
}

.footer-item p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-top: 20px;
}

.footer-item h4 {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.footer-item ul li {
  padding: 5px 0;
}

.footer-item ul li a {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
}

.footer-item .social-icon li {
  display: inline-block;
  padding: 0 5px;
}

.footer-item .social-icon li a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background: #fff;
  color: #001722;
  border-radius: 50%;
  transition: 0.2s all ease;
}

.footer-item .social-icon li a:hover {
  opacity: 0.8;
}

.footer-item img {
  width: 100%;
}

.copyright-part {
  padding-top: 50px;
  text-align: center;
}

.copyright-part p {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
}

.footer-area {
  position: relative;
}

/*===================
modal-area start
=====================*/
.modal-area {
  z-index: 99999;
  position: relative;
}

.modal-dialog {
  max-width: 900px;
}

.modal-header h5 {
  font-size: 18px;
  font-weight: 700;
  color: #191919;
  text-transform: uppercase;
  margin-left: 15px;
}

@media screen and (max-width: 700px) {
  .footer-item h4 {
    margin-top: 30px;
  }

  .footer-item img {
    margin-top: 30px;
  }
}
