.privacy-area {
  padding: 50px 0;
  margin-top: 80px;

  h3 {
    font-weight: 700;
    margin-bottom: 30px;
  }

  p {
    font-weight: 500;
    font-size: 15px;
    color: #4e4e4e;
  }
}
