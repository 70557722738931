.thanks-page {
  margin-top: 70px;
  .thanks-content {
    max-width: 700px;
    padding: 70px 0;
    margin: auto;
    text-align: center;
    .thanks {
      h2 {
        font-size: 60px;
        font-weight: 800;
        font-family: "Ubuntu", sans-serif;
        font-size: 60px;
        font-weight: bold;
        color: #121212;
        text-align: center;
        letter-spacing: 5px;
        text-shadow: 2px 7px 5px rgba(0, 0, 0, 0.3),
          0px -4px 10px rgba(255, 255, 255, 0.3);
        margin-bottom: 20px;
      }
      h4 {
        font-weight: 700;
        font-size: 20px;
      }
      .img {
        margin: 30px auto;
        max-width: 250px;

        img {
          display: inline-block;
          width: 100%;
        }
      }
    }
  }
}
