/*====================
hero-section start
======================*/
.hero-area {
  overflow: hidden;
  margin-top: 90px;
}

.hero-left h2 {
  font-size: 33px;
  font-weight: 700;
  color: #ffcb00;
  text-transform: uppercase;
  line-height: 46px;
}

.hero-left h2 span {
  color: #fff;
}

.hero-left p {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  margin: 25px 0 40px;
  text-decoration: none;
}

.hero-left a {
  font-size: 18px;
  font-weight: 700;
  color: #000;
  background: #ffcb00;
  text-transform: uppercase;
  display: inline-block;
  border: 1px solid #ffcb00;
  padding: 12px 60px;
  border-radius: 8px;
  text-decoration: none;
}

.hero-left a i {
  display: inline-block;
  margin-left: 6px;
}

.hero-left a:hover {
  background: transparent;
  color: #ffcb00;
  border-color: #ffcb00;
}

.hero-right img {
  width: 100%;
  border-radius: 20px;
}

.carousel-item {
  padding: 105px 0;
}

.slider1 {
  background: #001722;
  text-decoration: none;
}

.slider2 {
  background: #e3edfe;
}

.slider3 {
  background: linear-gradient(to right, #32bacf, #1f3259);
}

.slider2 h2 {
  color: #3b58b0;
}

.slider2 h2 span {
  color: #29429d;
}

.slider2 p {
  color: #243fa1;
}

.slider2 a {
  background: #243fa1;
  color: #fff;
  border-color: #243fa1;
  text-decoration: none;
}

.slider2 a:hover {
  border-color: #243fa1;
  color: #243fa1;
}

.slider3 h2 {
  color: #1b1535;
}

.slider3 p {
  color: #1b1535;
}

.slider3 a {
  background: #1b1535;
  color: #fff;
  border-color: #1b1535;
  text-decoration: none;
}

.slider3 a:hover {
  border-color: #1b1535;
  color: #1b1535;
}

.carousel-indicators [data-bs-target] {
  background-color: #a5cd37;
  width: 50px;
  height: 7px;
}

@media screen and (max-width: 767px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .modal-backdrop.show {
    opacity: 0.5;
  }

  .modal-backdrop {
    background-color: #000000;
  }

  .navbar-brand img {
    max-width: 121px;
  }

  .slider3 {
    background: linear-gradient(to top, #32bacf, #1f3259);
  }

  .hero-left h2 {
    font-size: 28px;
  }

  .hero-left p,
  .hero-left a {
    font-size: 17px;
    text-decoration: none;
  }

  .hero-left {
    margin-top: 40px;
  }

  .carousel-item {
    padding: 55px 0 85px;
  }

  .carousel-item a {
    text-decoration: none !important;
  }
  .radio-button-opt {
    position: relative;
    padding-top: 50px;
  }

  .radio-button-opt p {
    display: block;
    position: absolute;
    top: 12px;
  }
}
