/*===================
contact-area start
=====================*/
.contact-area {
  padding-bottom: 80px;
  overflow: hidden;
}

.contact-main {
  padding-top: 40px;
}

.contact-left iframe {
  width: 100%;
  height: 250px;
}

.contact-left ul {
  padding-top: 20px;
}

.contact-left ul li {
  padding: 5px 0;
}

.contact-left ul .addres {
  display: flex;
  align-items: flex-start;
}

.contact-left ul .addres i {
  margin-right: 14px;
}

.contact-left ul li,
.contact-left ul li a {
  font-size: 18px;
  font-weight: 400;
  color: #191919;
  text-decoration: none;
  transition: 0.2s all ease;
}

.contact-left ul li i {
  width: 38px;
  height: 38px;
  line-height: 38px;
  background: #218a42;
  color: #fff;
  font-size: 18px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-right: 10px;
}

.contact-left ul li a:hover {
  color: #218a42;
}

.contact-right input,
.contact-right textarea {
  width: 100%;
  display: block;
  font-size: 18px;
  font-weight: 400;
  color: #000;
  background: #f1f1f1;
  border: 1px solid #d1d1d1;
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 15px;
  outline: none;
  resize: none;
}

.contact-right button {
  width: 200px;
  display: block;
  font-size: 17px;
  font-weight: 500;
  color: #fff;
  background: #218a42;
  text-transform: uppercase;
  border: none;
  outline: none;
  padding: 14px 15px;
  border: 1px solid #218a42;
  border-radius: 7px;
  margin-top: 30px;
  transition: 0.2s all ease;
}

.contact-right button:hover {
  background: transparent;
  color: #218a42;
}
