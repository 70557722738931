/*===================
gallery-area start
=====================*/
.gallery-area {
  overflow: hidden;
}
.gallery-item img {
  width: 100%;
}

.gallery-item {
  margin: 14px 0;
}
