.term-page {
  padding: 50px 0;
  margin-top: 80px;
  p {
    color: #707070;
    font-weight: 600;
    font-size: 15px;
  }
  .introduction {
    margin: 50px 0;
  }

  .list {
    margin: 30px 0;
    li {
      display: block;
      color: #707070;
      margin: 15px 0;
      font-size: 15px;
      font-weight: 500;
    }
  }

  .hope-text {
    margin-top: 50px;
  }
}
