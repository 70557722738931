/*===================
header-area start
=====================*/
.header-area {
  padding: 20px 0;
  position: fixed;
  top: 0;
  width: 100%;
  display: block;
  z-index: 999;
  background-color: #fff;
  transition: 0.3s all ease;
  overflow-x: hidden;
}

.headerfixed {
  box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 15px 10px -15px rgba(0, 0, 0, 0.25);
}

.navbar-brand img {
  max-width: 150px;
  transition: 0.3s all ease;
}

.navbar-nav li {
  padding: 10px 16px;
}

.navbar-nav li a {
  font-size: 18px;
  font-weight: 500;
  color: #191919;
  transition: 0.2s all ease;
  text-transform: uppercase;
  position: relative;
}

.navbar-nav li a::after {
  content: "";
  width: 0%;
  height: 2px;
  background: #218a42;
  display: block;
  transition: 0.4s;
}

.navbar-nav li a:hover::after,
.navbar-nav li .active::after {
  width: 100%;
}

.navbar-nav li a:hover,
.header-area ul li .active {
  color: #218a42;
}

.navbar-nav li .nav-link {
  color: #191919;
  padding: 0 !important;
}

.nav-btn li {
  padding: 10px 8px;
}

.nav-btn li a {
  background: transparent;
  border: 2px solid #218a42;
  display: inline-block;
  padding: 10px 24px;
  border-radius: 7px;
  text-decoration: none;
}

.nav-btn li a::after {
  display: none;
}

.nav-btn li a:hover {
  background: #218a42;
  color: #fff;
}

.navbar-toggler {
  outline: none !important;
  box-shadow: none !important;
  padding: 2px;
  transition: 0.2s all ease;
}

.navbar-toggler:hover {
  opacity: 0.75;
}

/*hamburger*/
.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: #000;
  border-radius: 4px;
  position: absolute;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: "";
  display: block;
  outline: none;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

/*side-bar*/
.offcanvas-start {
  width: 320px;
  z-index: 999999999;
}

.offcanvas-header img {
  max-width: 120px;
}

.offcanvas-body ul li {
  padding: 10px 10px;
}

.offcanvas-body ul li .nav-link {
  padding: 0;
  display: inline-block;
}

.btn-close {
  opacity: 1;
  font-size: 25px;
  outline: none !important;
  box-shadow: none !important;
  transition: 0.2s all ease;
}

@media screen and (max-width: 767px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .modal-backdrop.show {
    opacity: 0.5;
  }

  .modal-backdrop {
    background-color: #000000;
  }

  .navbar-brand img {
    max-width: 121px;
  }
}

@media screen and (max-width: 575px) {
  .modal-dialog {
    padding: 0;
  }
}
