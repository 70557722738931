/*=========
fonts
===========*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,400;1,700&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  background: #fff;
  color: #191919;
  overflow-x: hidden;
}

a {
  display: inline-block;
  text-decoration: none;
  transition: 0.2s all ease;
  -webkit-transition: 0.2s all ease;
  -moz-transition: 0.2s all ease;
}

a:visited,
a:hover,
a:active {
  text-decoration: none;
}

ul {
  list-style-type: none;
  padding: 0 !important;
  margin: 0;
}

img {
  max-width: 100%;
}

::selection {
  color: white;
  background: #218a42;
}

::-webkit-selection {
  color: white;
  background: #218a42;
}

::-moz-selection {
  color: white;
  background: #218a42;
}

/*  styling scrollbars  */
::-webkit-scrollbar {
  width: 5px;
  height: 6px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #a5aaad;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3ea175;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a5aaad;
}

* {
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.text-primary-p {
  color: #a5aaad;
  font-size: 14px;
  font-weight: 700;
}

.font-bold {
  font-weight: 700;
}

.text-title {
  color: #2e4a66;
}

.text-lightblue {
  color: #469cac;
}

.text-red {
  color: #cc3d38;
}

.text-yellow {
  color: #a98921;
}

.text-green {
  color: #3b9668;
}

.dashbaord-container {
  display: flex;
}
.main-dashboard {
  width: calc(100% - 220px);
}

@media screen and (min-width: 320px) and (max-width: 978px) {
  .main-dashboard {
    width: 100% !important;
  }
}
