/*=========================
registration page start
===========================*/
.main-wraper2 {
  background-image: url(../../images//register.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 80px 0;
  z-index: 99;
  margin-top: 60px;
}

.main-wraper2::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -10;
  background: rgba(0, 0, 0, 0.9);
  top: 0;
  left: 0;
}

.register-form h2 {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.register-form h2::after {
  content: "";
  display: block;
  width: 140px;
  height: 2px;
  background: #fff;
  margin: 12px auto;
}

.register-form form {
  max-width: 800px;
  margin: 50px auto 0;
}

.form-info input,
.form-info select,
.form-info textarea {
  width: 100%;
  display: block;
  font-size: 18px;
  font-weight: 500;
  color: #191919;
  background: #fff;
  padding: 12px 20px;
  border: none;
  outline: none;
  border-radius: 6px;
  margin-bottom: 16px;
  resize: none;
}

.form-info input::placeholder,
.form-info select::placeholder,
.form-info textarea::placeholder {
  color: #191919;
  opacity: 5 !important;
}

.form-info select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../../images/down.png);
  background-repeat: no-repeat;
  background-position: 98% 50%;
  background-size: 30px;
  font-weight: 500;
  cursor: pointer;
}

.form-info button {
  width: 200px;
  font-size: 18px;
  font-weight: 700;
  color: #191919;
  text-transform: uppercase;
  display: block;
  text-align: center;
  margin: 32px auto 0;
  background: #fff;
  border: 2px solid #fff;
  outline: none;
  padding: 12px 15px;
  border-radius: 8px;
  transition: 0.2s all ease;
}

.form-info button:hover {
  opacity: 0.8;
  background: transparent;
  color: #fff;
}
